.block-categories-wrapper {
  padding: 90px 0;

  .block-categories {
    &__header {
      text-align: center;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 2rem; // Parent kategori büyük olacak.
      font-weight: 300;
      color: rgba(0, 0, 0, 0.7); // Siyah ama silik (0.7 opaklık).
    }

    &__body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    &__item {
      flex: 1 1 calc(33.333% - 20px); // Kartların boyutları eşit olacak.
      min-width: 250px;
    }
  }

  .category-card {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    padding: 10px;
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &__image {
      margin-bottom: 15px;

      img {
        max-width: 250px;
        height: 250px;
      }
    }

    &__info {
      text-align: center;
    }

    &__name {
      font-size: 1.5rem;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.7); // Siyah ama soluk (0.7 opaklık).
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__children a {
      font-size: 1rem;
      font-weight: bold;
      color: rgba(105, 105, 105, 0.7); // Turuncuya uyumlu, silik gri.
      margin-bottom: 10px;

      li {
        margin: 5px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__actions {
      margin-top: auto;
    }

    .category-card__link {
      color: #d97706;
      font-weight: bold;
    }
  }
}
