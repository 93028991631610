.image-box .image{
  display: none;
}
.image-box .image-active{
  display: block;
}

.customer-comment-input{
  display: flex;
  flex-direction: column;
}