// InstallmentTable component styles

.installment-table {
  margin: 20px 0;

  .bank-section {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;

    h3 {
      font-size: 1.5em;
      margin-bottom: 15px;
      color: #333;
    }

    p {
      color: #d9534f; // Highlight for mandatory 3D Secure message
      font-weight: bold;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        text-align: left;
        padding: 10px;
        border-bottom: 1px solid #ddd;
      }

      th {
        background-color: #f2f2f2;
        font-weight: bold;
      }

      td {
        color: #555;
      }

      tr:nth-child(even) {
        background-color: #f9f9f9; // Alternate row colors
      }
    }
  }
}
