.tags-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 10px;
  .tag {
    display: inline-block;
    padding: 5px 10px;
    margin: 5px;
    background-color: #f1f1f1;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    transition: all 0.3s;
    .delete-tag{
      margin-left: 5px;
      font-size: 14px;
      color: #333;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
