.brand{
  .brand-container{
    height: 100%;
  }
  .brand-logo{
    img{
      width: 60px;
      height: 60px;
    }
  }
}