.main-footer {
    background-color: #2B313E;
    padding: 20px 0;
    font-size: 14px;
  
    .footer-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .footer-icon{
        img{
            width: 150px;
            height: 150px;
        }
      }
    }
  
    .footer-column {
      flex: 1;
      min-width: 200px;
      margin: 10px;
  
      h4 {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
  
      ul {
        list-style: none;
        padding: 0;
  
        li {
          margin-bottom: 8px;
  
          a {
            color: #f8f8f8;
            text-decoration: none;
  
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
  
      .social-icons {
        a {
          margin-right: 10px;
          font-size: 20px;
        }
      }
  
      img {
        margin-top: 10px;
      }
    }
  
    /* Bottom Bar */
    .bottom-bar {
      background-color: #e1e1e1;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border-top: 1px solid #ccc;
    }
  
    .copyright-text {
      font-size: 12px;
      color: #333;
  
      span {
        font-weight: bold;
      }
    }
  
    .copyright-card {
      display: flex;
      align-items: center;
  
      a {
        margin: 0 5px;
  
        img {
          height: 25px; /* Adjust the size of the card icons */
        }
      }
    }
  
    @media (max-width: 768px) {
      .footer-container {
        flex-direction: column;
        align-items: center;
      }
  
      .footer-column {
        text-align: center;
        margin-bottom: 20px;
      }
  
      .bottom-bar {
        flex-direction: column;
        align-items: center;
      }
  
      .copyright-card {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  