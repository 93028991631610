.form-control {
    border-radius: 2px;
    background-clip: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-transition: border-color .15s ease-in-out, background .15s ease-in-out, opacity .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, background .15s ease-in-out, opacity .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, background .15s ease-in-out, opacity .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, background .15s ease-in-out, opacity .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, background .15s ease-in-out, opacity .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    color: #262626;
    background-color: #ebebeb;
    border: 1px solid #ebebeb;
    height: 36px;
    padding: calc(15px / 2) 10px;
    font-size: 16px;
    line-height: 19px;
}

.form-control::-webkit-input-placeholder {
    color:#6c757d
}

.form-control::-moz-placeholder {
    color:#6c757d
}

.form-control:-ms-input-placeholder {
    color:#6c757d
}

.form-control::placeholder {
    color:#6c757d
}

.form-control[readonly] {
    color: #262626;
    background-color: #ebebeb;
    border-color:#ebebeb
}

.form-control[readonly]::-webkit-input-placeholder {
    color:#6c757d
}

.form-control[readonly]::-moz-placeholder {
    color:#6c757d
}

.form-control[readonly]:-ms-input-placeholder {
    color:#6c757d
}

.form-control[readonly]::placeholder {
    color:#6c757d
}

.form-control:focus, .form-control:hover {
    border-color:#d9d9d9
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color:#fff
}

.form-control:disabled {
    opacity: .7;
    color: #262626;
    background-color: #ebebeb;
    border-color:#ebebeb
}

.form-control:disabled::-webkit-input-placeholder {
    color:#6c757d
}

.form-control:disabled::-moz-placeholder {
    color:#6c757d
}

.form-control:disabled:-ms-input-placeholder {
    color:#6c757d
}

.form-control:disabled::placeholder {
    color:#6c757d
}

.form-control.is-invalid:focus, .form-control.is-valid:focus, .was-validated .form-control:invalid:focus, .was-validated .form-control:valid:focus {
    -webkit-box-shadow: none;
    box-shadow:none
}

.form-control.is-invalid, .form-control.is-valid, .was-validated .form-control:invalid, .was-validated .form-control:valid {
    background-image:none
}

.form-control.is-valid, .was-validated .form-control:valid {
    color: #262626;
    background-color: #e2f2da;
    border-color:#e2f2da
}

.form-control.is-valid::-webkit-input-placeholder, .was-validated .form-control:valid::-webkit-input-placeholder {
    color:#6c757d
}

.form-control.is-valid::-moz-placeholder, .was-validated .form-control:valid::-moz-placeholder {
    color:#6c757d
}

.form-control.is-valid:-ms-input-placeholder, .was-validated .form-control:valid:-ms-input-placeholder {
    color:#6c757d
}

.form-control.is-valid::placeholder, .was-validated .form-control:valid::placeholder {
    color:#6c757d
}

.form-control.is-valid:hover, .was-validated .form-control:valid:hover {
    border-color:#c3d9b8
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
    background-color: #fff;
    border-color:#8c6
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    color: #262626;
    background-color: #f2dada;
    border-color:#f2dada
}

.form-control.is-invalid::-webkit-input-placeholder, .was-validated .form-control:invalid::-webkit-input-placeholder {
    color:#6c757d
}

.form-control.is-invalid::-moz-placeholder, .was-validated .form-control:invalid::-moz-placeholder {
    color:#6c757d
}

.form-control.is-invalid:-ms-input-placeholder, .was-validated .form-control:invalid:-ms-input-placeholder {
    color:#6c757d
}

.form-control.is-invalid::placeholder, .was-validated .form-control:invalid::placeholder {
    color:#6c757d
}

.form-control.is-invalid:hover, .was-validated .form-control:invalid:hover {
    border-color:#d9a3a3
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    background-color: #fff;
    border-color:#c66
}

.form-control-lg {
    height: 42px;
    padding: calc(19px / 2) 12px;
    font-size: 18px;
    line-height:21px
}

.form-control-sm {
    height: 30px;
    padding: calc(11px / 2) 8px;
    font-size: 14px;
    line-height:17px
}

select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance:none
}

select.form-control:not(:disabled) {
    cursor:pointer
}

.was-validated select.form-control:invalid, .was-validated select.form-control:valid, select.form-control, select.form-control.is-invalid, select.form-control.is-valid {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='rgba(0, 0, 0, 0.55)' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size:5px 10px
}

select.form-control::-ms-expand {
    display:none
}

[dir=ltr] select.form-control {
    padding-right: 27px;
    background-position:right 10px center
}

[dir=rtl] select.form-control {
    padding-left: 27px;
    background-position:left 10px center
}

[dir=ltr] select.form-control-lg {
    padding-right: 31px;
    background-position:right 12px center
}

[dir=rtl] select.form-control-lg {
    padding-left: 31px;
    background-position:left 12px center
}

[dir=ltr] select.form-control-sm {
    padding-right: 23px;
    background-position:right 8px center
}

[dir=rtl] select.form-control-sm {
    padding-left: 23px;
    background-position:left 8px center
}

