/** shop-page-section **/

.shop-sidebar .search-widget .search-form .form-group input[type='search']{
	position: relative;
	display: block;
	width: 100%;
	height: 50px;
	border: 1px solid #e5e5e5;
	background: #f7fafc;
	font-size: 16px;
	color: #808080;
	padding: 10px 20px 10px 50px;
	transition: all 500ms ease;
}

.shop-sidebar .search-widget .search-form .form-group button{
	position: absolute;
	left: 20px;
	top: 12px;
	font-size: 18px;
	color: #808080;
	cursor: pointer;
	transition: all 500ms ease;
}

.shop-sidebar .sidebar-widget{
	border-bottom: 1px solid #e5e5e5;
}

.shop-sidebar .sidebar-widget:last-child{
	border-bottom: none;
}

.shop-sidebar .category-widget .category-list li a:before{
	position: absolute;
	content: '';
	background: #000;
	width: 6px;
	height: 6px;
	left: 0px;
	top: 11px;
	border-radius: 50%;
	transition: all 500ms ease;
}

.range-slider .title:before {
  position: absolute;
  content: '$';
  left: -5px;
  top: -19px;
  color: #808080;
  font-weight: 500;
  font-size: 15px;
  font-family: "Inter", sans-serif;
}

.range-slider .input{
  color:#141417;
  max-width: 75px;
}

.range-slider .input input{
  background:none;
  color:#808080;
  font-size:15px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  text-align:left;
  width: 80px;
}

.range-slider .ui-widget.ui-widget-content{
  height:3px;
  border:none;
  margin-bottom:18px;
  background:#f0f0f0; 
}

.range-slider .ui-slider .ui-slider-range{
  top:0px;
  height:3px;
}

.range-slider .ui-state-default,
.range-slider .ui-widget-content .ui-state-default{
  top:-5px;
  width:13px;
  height:13px;
  border: none;
  border-radius:50%;
  background: #212121;
  cursor:pointer; 
  margin-left: 0px;
  box-shadow: 0px 5px 15px 0 rgba(0,0,0,0.4);
}

.range-slider .ui-slider .ui-slider-range{
	background: #212121;
}

.shop-sidebar .tags-widget .tags-list{
	margin: 0px -5px;
}

.shop-sidebar .tags-widget .tags-list li a{
	padding: 2px 13.5px;
	border: 1px solid #e5e5e5;
}

.shop-sidebar .tags-widget .tags-list li a:hover{
	color: #fff;
}

.wrapper.list .shop-list-content{
  display: block;
}

.wrapper.list .shop-grid-content{
  display: none
}

.wrapper.grid .shop-list-content{
  display: none;
}

.wrapper.grid .shop-grid-content{
  display: block
}

.shop-page-section .item-shorting .left-column .btn-box button{
	position: relative;
	display: inline-block;
	font-size: 20px;
    width: 18px;
	color: #242426;
	margin-right: 12px;
	transition: all 500ms ease;
}

.shop-page-section .item-shorting .left-column .btn-box button:last-child{
	margin: 0px !important;
}

.shop-page-section .item-shorting .nice-select{
	height: auto;
	line-height: 26px;
	border: none !important;
	font-size: 16px;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
	color: #141417;
	padding-left: 0px;
	padding-right: 35px;
}

.shop-page-section .item-shorting .nice-select:after{
	right: 0px;
	color: #222222;
	top: 1px;
}

.shop-page-section .item-shorting .nice-select .list{
	min-width: 160px;
}

.grid-view, .list-view{
    width: 18px;
}

.shop-sidebar .tags-widget .tags-list li a:hover{
	color: #e25712;
} 

.shop-page-section .lower-content{
    padding-top: 20px;
}

.shop-grid-row{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.shop-sidebar .category-list{
	max-height: 350px;
	overflow: auto;
}

