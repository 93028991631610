.vehicles-list__body {
  display: flex;
  flex-wrap: wrap;
  margin: -(8px / 2);
}
.vehicles-list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  width: 100%;
  margin: (8px / 2);
}
.vehicles-list__item-radio {
  margin: 8px;
}
.vehicles-list__item-info {
  margin: 2px 8px;
  flex-grow: 1;
  line-height: 1.25;
}
.vehicles-list__item-remove {
  margin: 8px;

  svg {
    display: block;
  }
}
.vehicles-list__item-name {
  display: block;
  font-size: 15px;
  font-weight: normal;
}
.vehicles-list__item-details {
  display: block;
  font-size: 13px;
  margin-top: 2px;
}
.vehicles-list__item-links {
  font-size: 14px;
  margin-top: 12px;

  a:hover {
    text-decoration: underline;
  }
}
.vehicles-list_item-image {
  img {
    width: 70px;
    height: 50px;
  }
}
.vehicles-list__item-remove {
  position: relative;
  display: flex;
  padding: 8px;
  border: none;
  margin: 0;
  border-radius: 2px;
  fill: currentColor;
  transition:
    background-color 0.15s,
    color 0.15s;

  &:focus {
    outline: none;
  }

  &.vehicles-list__item-remove--loading {
    cursor: default;
    fill: transparent;
    background: transparent;
  }
}

.vehicles-list--layout--account {
  .vehicles-list__item {
    padding: 10px 8px;
    width: calc(50% - #{12px});
    margin: (12px / 2);
  }
}

.vehicle-search-or{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

@media (max-width: 768px) {
  .vehicles-list--layout--account .vehicles-list__item {
    width: 100%;
  }
  .vehicles-list__item-info {
    margin: 8px 0;
  }
  .vehicles-list__item-remove {
    margin: 8px;
  }
  .vehicles-list__item-links {
    display: flex;
    height: 30px;
  }
}
