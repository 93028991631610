.refund-policy {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  line-height: 1.6;

  h2 {
    color: #333;
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  p {
    color: #555;
    margin-bottom: 10px;
    
    a {
      color: #1e88e5;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      font-weight: bold;
    }
  }

  .refund-notice {
    margin-top: 20px;
    font-size: 0.9rem;
    color: #777;
  }
}
