.profile-container{
    margin: 24px auto;
}
.account-nav {
    background-color:#F5F5F5;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 10px;
}
.account-nav__title {
    padding: 1.375rem 1.5rem;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
}
.account-nav__list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
}
.account-nav__item a,
.account-nav__item button {
    cursor: pointer;
    display: block;
    padding: 7px 1.5rem;
    border: none;
    width: 100%;
    background: transparent;
    border-bottom: 1px solid #e9e5df;

    &:focus {
        outline: none;
    }

    &.active{
        color: inherit;
        font-weight: bold;
    }
}
.account-nav__item:hover a,
.account-nav__item:hover button {
    background: #F5F5F5;
}
.account-nav__item--active a,
.account-nav__item--active button {
    color: inherit;
    font-weight: bold;
}
.account-nav__divider {
    height: 1px;
    margin: 10px 0;
}
.profile-card {}
.profile-card__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.profile-card__avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-bottom: 16px;

    img {
        border-radius: 50%;
        max-width: 100%;
    }
}
.profile-card__name {
    line-height: 20px;
}
.profile-card__email {
    font-size: 15px;
    margin-bottom: 24px;
}

.addresses-list {
    display: flex;
    flex-wrap: wrap;
}
.addresses-list__item {
    flex-shrink: 0;
}
.addresses-list__item--new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}
.addresses-list__divider:last-child {
    display: none;
}
.addresses-list__plus {
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 30px;
    margin-bottom: 24px;
    color: #e9e5df;
    border: 2px solid currentColor;
    transition: color .2s;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 32px;
        height: 4px;
        background: currentColor;
        border-radius: 1.5px;
        top: calc(50% - 2px);
    }

    &:after {
        transform: rotateZ(90deg);
    }
}
.addresses-list__item--new:hover .addresses-list__plus {
    color: #F5F5F5;
}
.dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.dashboard__orders {
    margin-top: 24px;
    width: 100%;
}
.dashboard__profile,
    .dashboard__address {
        width: calc(50% - 12px);
}
.address-card {
    position: relative;
}
.address-card__badge {
    position: absolute;
    font-size: 12px;
    top: 12px;
    right: 12px;
    background-color: #E85230;
    color: #fff;
    padding: 0 4px;
    border-radius: 8px;
}
.address-card__body {
    padding: 1.5rem;
    font-size: 15px;
    line-height: 18px;
}
.address-card__name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1.125rem;
}
.address-card__row + .address-card__row {
    margin-top: .75rem;
}
.address-card__row-title {
    font-size: 13px;
    color: #e9e5df;
    font-weight: bold;
}
.address-card__footer {
    margin-top: 1.625rem;

    a:hover {
        text-decoration: underline;
    }
}
.address-card--featured {
    .address-card__body {
        padding: 2rem;
    }
    .address-card__name {
        font-weight: bold;
    }
}

@media (max-width: 768px) {
    .dashboard {
        flex-direction: column;
    }
    .dashboard__profile,
    .dashboard__address {
        width: 100%;
    }
    
}
