.mobile-scroll-top-button {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 999;
  background: #e35712;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  transition: all 500ms ease;
}