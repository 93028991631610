.vehicles-list__item {
  position: relative;
  .vehicles-list__item-close{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 5px;
    width: 30px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 1;
    &:hover{
      background-color: #f0f0f0;
    }
  }
}