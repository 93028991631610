.order-detail-card {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

  .card-header {
    padding: 1rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;

    .order-title {
      font-size: 1.25rem;
      font-weight: bold;
    }

    .order-status {
      font-size: 1rem;
      color: #6c757d;

      mark {
        background-color: #ffc107;
        color: #fff;
        padding: 0.1rem 0.3rem;
        border-radius: 0.25rem;
      }
    }
  }

  .card-body {
    padding: 1rem;

    .order-items {
      .order-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 1.5rem;

        th, td {
          padding: 0.75rem;
          border: 1px solid #dee2e6;
        }

        th {
          text-align: left;
          background-color: #f8f9fa;
        }

        tfoot {
          th {
            font-weight: bold;
          }
        }
      }
    }

    .addresses {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem;

      .address {
        width: 48%;

        h6 {
          font-weight: bold;
          margin-bottom: 0.75rem;
        }

        p {
          margin: 0.25rem 0;
        }
      }
    }
  }
}
