.header-select {
  background: #2B313E;
  position: relative;
  border: 1px solid #e5e5e5;
  padding: 15px 7.5%;

  .title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .selector {

    label {
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }

    select {
      width: 100%;
      padding: 8px;
      border-radius: 5px;
      border: 1px solid #ccc;
    }
  }

  .button {
    background-color: #e35712;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }

  .header-select__number-wrap {
    display: flex;
    gap: 10px;

    input {
      flex: 1;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      background-color: #fff;
      font-size: 14px;
      color: #000000;
    }

    .button {
      flex-shrink: 0;
    }
  }
}

.header-select .selector svg.circle-arrow, .header-select .selector.preloader:before {
  display:none
}

.header-select .selector.preloader select {
  cursor: default;
  pointer-events:none
}

.header-select .selector.preloader svg.circle-arrow {
  fill: #6ab0ff;
  animation: spin 1.2s linear 0s infinite;
  display: block;
  height: 22px;
  margin-top: -11px;
  position: absolute;
  right: 20px;
  top: 50%;
  width:22px
}

@media (max-width: 1440px) {
  .header-select .selector {
      height: 30px;
      line-height:30px
  }
}

@media (max-width: 1199px) {
  .header-select .selector {
      width:29.5%
  }
  .header-select .header-select__number:before {
    display: none;
  }

  .header-select .header-select__number{
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .header-select .selector {
      height: 40px;
      line-height: 40px;
      margin: 0 0 15px;
      width:100%
  }
  .header-select .header-select__number:before {
    display: none;
  }

  .header-select .row .selector {
    width: 100%;
  }

  .header-select .header-select__number{
    padding-left: 0;
  }
}

@media (max-width: 575px) {
  .header-select .selector.form-error {
      margin-bottom:50px
  }
  .header-select .header-select__number:before {
    display: none;
  }
  .header-select__choosse-wrap, .header-select__number-wrap {
    flex-direction: column;
  }

  .header-select .row .selector {
    width: 100%;
  }

  .header-select .header-select__number{
    padding-left: 0;
  }
  
}

.header-select__choosse-wrap, .header-select__number-wrap {
  display: flex;
  justify-content: space-between;
}

.header-select .selector:before {
  border-bottom: 1px solid red;
  border-right: 1px solid red;
  content: "";
  display: block;
  height: 6px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 18px;
  top: 50%;
  transform: rotate(45deg);
  width: 6px;
}

.header-select .title {
  color: #fff;
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 15px;
  width: 100%;
}

.header-select .selector select {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  height: 100%;
  outline: none;
  padding: 0 30px 0 20px;
  width: 100%;
  position: absolute;
  top: 0;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.header-select .selector:before {
  border-bottom: 1px solid red;
  border-right: 1px solid red;
  content: "";
  display: block;
  height: 6px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 18px;
  top: 50%;
  transform: rotate(45deg);
  width:6px
}

@media (max-width: 575px) {
  .header-select .selector:before {
      border-bottom: 2px solid red;
      border-right: 2px solid red;
      height: 10px;
      margin-top: -7px;
      right: 25px;
      width:10px
  }
  .header-select .header-select__number:before {
    display: none;
  }
  .header-select .header-select__number{
    padding-left: 0;
  }
}

@media only screen and (max-width: 320px) {
  .header-select .selector:before {
      height: 8px;
      margin-top: -6px;
      right: 22px;
      width:8px
  }

  .header-select .header-select__number:before {
    display: none;
    left: 0;
  }

  .header-select .header-select__number{
    padding-left: 0;
  }
}

.header-select .selector {
  background: #fff;
  border-radius: 40px;
  box-sizing: border-box;
  cursor: pointer;
  height: 39px;
  line-height: 39px;
  margin-right: 20px;
  position: relative;
  width:27.5%;
}

.header-select .selector--tyres {
  width:15.5%
}

.header-select__number:before {
  background: hsla(0, 0%, 100%, .5);
  content: "";
  display: block;
  height: calc(100% + 10px);
  position: absolute;
  top: 0;
  width: 1px;
  left: 10px;
}

.header-select__number{
  padding-left: 25px;
}
