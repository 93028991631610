.mobile-search-box-wrapper{
  display: none;
}

.search-box {
  .search-result {
    position: absolute;
    background-color: white;
    padding: 16px 16px;
    top: 80px;
    left: 0;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    .search-result-item-title {
      font-size: 10px;
      font-weight: 600;
      color: #333;
      margin-bottom: 5px;
    }
    .search-result-item-content {
      font-size: 14px;
      color: #666;
      margin-bottom: 5px;
    }
    .search-result-item {
      cursor: pointer;
      padding: 5px;
      border-bottom: 1px solid #f0f0f0;
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}

.product-search-result{
  display: flex;
  .product-search-result-image{
    width: 80px;
    height: 80px;
    margin-right: 50px;
  }
}

@media (max-width: 768px) {
  .favorite, .search-toggler {
    display: none;
  }
  .ex_shop_header .header-lower .navbar-right-info {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  .search-box{
    display: none;
  }
  .mobile-search-box-wrapper{
    display: block;
    .search-box {
      display: block;
      .search-result {
        top: 60px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .search-box {
    display: none;
    .search-result {
      top: 60px;
    }
  }

  .mobile-search-box-wrapper{
    display: block;
    position: relative;
    .search-box {
      padding: 13px 14px 14px 24px;
      max-width: unset;
      display: block;
      .search-result {
        top: 60px;
      }
    }
  }
  
}

.menupop-container{
  .menupop-sidebar{
    
  }
}
